html {
  font-size: 62.5%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch;

  body {
    background-repeat: repeat;
    font-size: 1.4rem;
    line-height: 1.2;
    overflow: hidden;

    * {
      appearance: none;
    }

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    button,
    input,
    textarea {
      border-radius: 0;
    }

    iframe,
    img,
    svg {
      vertical-align: middle;
    }

    ul {
      list-style: none;
    }
  }
}
